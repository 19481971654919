import React, { createContext, useState, ReactNode, FC } from "react";

// Define el tipo de los valores que tendrás en el contexto
interface MyContextType {
  test: string;
  setTest: React.Dispatch<React.SetStateAction<string>>;
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

export const AppContext = createContext<MyContextType | undefined>(undefined);

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [test, setTest] = useState<string>("Valor inicial");
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <AppContext.Provider value={{ test, setTest, currentStep, setCurrentStep }}>
      {children}
    </AppContext.Provider>
  );
};
